.news-slide{
    position: absolute;
    width: 36%;
    height: 24.5%;
    left: 61.9%;
    top: 71.7%;
    overflow: hidden;
    border-radius: 0.5vh;
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
}

.news-slide-dr{
    position: relative;
    box-sizing: border-box;
    background: rgba(255,255,255,0.3);
    color: black;
    padding: 1vh;
    height: 24.5vh;
}

.news-slide-dr h3{
    float: left;
    text-align: left;
    padding: 0;
    font-size: 2.0vh;
    line-height: 2.0vh;
    margin: 0 0 1vh;
    width: 65%;
    text-overflow: ellipsis;
}

.news-slide-dr p{
    float: left;
    font-size: 1.8vh;
    line-height: 1.8vh;
    max-width: 48%;
    box-sizing: border-box;
    margin: 0;
    text-align: left;
    padding: 0 0 1vh 0;
}

.news-slide-dr p.full-width{
    max-width: 100%;
    padding: 0;
}

.news-slide-dr p a{
    text-decoration: none;
}

.news-slide-dr .article-img{
    max-width: 50;
    max-height: 70%;

    padding-right: 1vh;
    float: left;
    bottom: 1vh;
}

.news-slide-dr .logo {
    position: absolute;
    width: 20%;
    top: 0;
    right: 0;
    padding: 1vh;
    box-sizing: border-box;
}